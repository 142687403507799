import React, { useState } from 'react';
import headerImage from '../images/header.png';
import footerImage from '../images/footer1.jpg';
import Login from './Login';
import '../styles/style.css';

export default function Main() {
  // State to control whether the Login component should be shown
  const [showLogin, setShowLogin] = useState(false);

  // Function to handle button click
  const handleLoginClick = () => {
    setShowLogin(true);
  };

  // If showLogin is true, render the Login component instead of the Main content
  if (showLogin) {
    return <Login />;
  }

  return (
    <div>
      {/* Your Main component content */}
      <div className="row">
        <img src={headerImage} style={{ width: '100%' }} alt="Header" />
      </div>
      <div id="phishing_block" style={{ display: 'block' }}>
        <div className="row banner_box">
          <div className="col-xs-12">
            <div style={{ marginTop: '10px' }}>
              {/* Button now directly calls handleLoginClick on click */}
              <button onClick={handleLoginClick} className="login_button" style={{ width: '60%', fontSize: '14px', backgroundColor: '2b9dcc', color: 'fff' }}>
                CONTINUE TO LOGIN
              </button>
              <span style={{ display: 'block', color: '10578d', fontWeight: 'bold', fontSize: '14px', marginTop: '10px', padding: '30px' }}>
                Dear Customer, OTP based login is introduced for added security.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <img src={footerImage} style={{ width: '100%' }} alt="Footer" className="footer-img" />
      </div>
    </div>
  );
}
