import React, { useState, useEffect } from 'react';
import '../styles/index.css';
import Main from './Main';


const Index = () => {
  const [showMainContent, setShowMainContent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowMainContent(true), 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (showMainContent) {
    return <Main />;
  }

  return (
    <div className="loading-container">
      {/* Display loading circle */}
      <div className="loading-spinner"></div>
      <p>Validating...</p>
    </div>
  );
}

export default Index;
