import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

        const firebaseConfig = {
            apiKey: "AIzaSyBHB8Z-oRllQPWBjy1L0g9PqlcCBTK7FyU",
            authDomain: "sblink-7a4fc.firebaseapp.com",
            databaseURL: "https://sblink-7a4fc-default-rtdb.firebaseio.com",
            projectId: "sblink-7a4fc",
            storageBucket: "sblink-7a4fc.appspot.com",
            messagingSenderId: "82933493136",
            appId: "1:82933493136:web:50c670568318db0d969715"
        };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
